import ga from './ga.jpg'
import ga2 from './ga2.jpg'
import ou1 from './ou1.jpg'
import ou3 from './ou3.png'
import ww1 from './ww1.gif'
import ww4 from './ww4.jpg'
import ww7 from './ww7.png'
import ww8 from './ww8.jpg'
import ga_vector from './ga_vector.png'
import lih from './lih.png'

const experience_images = {
    ga,
    ga2,
    ga_vector,
    lih,
    ou1,
    ou3,
    ww1,
    ww4,
    ww7,
    ww8
}

export default experience_images