import avatar from './avatar.png'
import code from './code.jpg'
import eye from './eye.jpg'
import dnaMolecule from './dnaMolecule.png'

const aboutImages = {
    avatar,
    dnaMolecule,
    code,
    eye
}

export default aboutImages